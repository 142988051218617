<template>
  <div class="container user-setting-payrate">
    <div class="page-header mb-1">
      <strong>เปิด/ปิด/ตั้งค่า อัตราจ่าย</strong>
    </div>
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="group in groups" :key="group._id" class="nav-item">
            <a class="nav-link" :class="[{'active':tabActiveGroup===group._id}]" href="#" target="_self" @click="tabActiveGroupId=group._id">{{ group.groupTitle }}</a>
          </li>
        </ul>
      </div>

      <div class="payrate-options p-1 mt-2">
        <button
          v-for="rate in payrates"
          class="btn mx-1"
          :key="rate._id"
          :class="[{'btn-primary':tabActivePayrate===rate._id}, {'btn-light text-primary':tabActivePayrate!==rate._id}]"
          @click="tabActiveRateId=rate._id"
          v-if="rate.status === 'Active'"
        >{{ rate.rateTitle }}</button>
      </div>

      <div class="p-2">
        <table class="table-accounts table table-hover table-bordered mb-0">
          <thead>
            <tr>
              <th class="text-center" rowspan="2">ลำดับ</th>
              <th class="text-center" rowspan="2">ชื่อใช้งาน</th>
              <th class="text-center" rowspan="2">ระดับ</th>
              <th class="text-center" rowspan="2">เปิด/ปิด</th>
              <th :colspan="marketOpenBets.length" class="text-center">จ่าย / ส่วนลด</th>
              <th class="text-center" rowspan="2">แก้ไข</th>
            </tr>
            <tr>
              <th v-for="bet in marketOpenBets" :key="bet.code" class="text-center">{{ bet.text }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, index) in items" :key="account._id">
              <td class="text-center">{{ index+1 }}</td>
              <td class="text-center">{{ account.user.username }}</td>
              <td class="text-center">{{ account.level.levelName }}</td>
              <td class="text-center">
                <b-form-checkbox v-model="account.groups[tabActiveGroupId].payRate[tabActiveRateId].isAvailable" name="check-button" switch @change="toggleChange(account)" :class="[account.groups[tabActiveGroupId].payRate[tabActiveRateId].isAvailable ? 'text-success' : 'text-danger']">
                  {{ account.groups[tabActiveGroupId].payRate[tabActiveRateId].isAvailable ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
              <td v-for="bet in marketOpenBets" :key="bet.code" class="text-center" :class="[{'bg-secondary': !account.groups[tabActiveGroupId].payRate[tabActiveRateId].isAvailable}]">
                <span v-if="account.groups[tabActiveGroupId].payRate[tabActiveRateId].rates[bet.code].isAvailable">{{ account.groups[tabActiveGroupId].payRate[tabActiveRateId].rates[bet.code].pay }}/{{ account.groups[tabActiveGroupId].payRate[tabActiveRateId].rates[bet.code].discount }}</span>
                <span v-else>-</span>
              </td>
              <td class="text-center" :class="[{'bg-secondary': !account.groups[tabActiveGroupId].payRate[tabActiveRateId].isAvailable}]">
                <button :disabled="!account.groups[tabActiveGroupId].payRate[tabActiveRateId].isAvailable" class="btn btn-sm btn-outline-primary" @click="editPayrate(account)"><i class="far fa-edit"></i></button>
              </td>
            </tr>
            <tr v-if="!items.length">
              <td class="text-center" :colspan="marketOpenBets.length + 5">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <EditPayrateModal :data="editData" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import { marketOpenBets } from "@/configs/market.config"

import AccountService from "@/services/accountService"
import Swal from 'sweetalert2'

import EditPayrateModal from './components/EditPayrateModal'

export default {
  name: 'Payrates',
  components: {
    EditPayrateModal
  },
  data() {
    return {
      tabActiveGroupId: null,
      tabActiveRateId: null,
      accounts: [],
      isShowModal: false,
      editData: null
    }
  },
  computed: {
    groups() {
      return this.$store.state.marketGroups
    },
    tabActiveGroup() {
      return this.tabActiveGroupId
    },
    payrates() {
      return this.$store.state.marketPayrates
    },
    tabActivePayrate() {
      return this.tabActiveRateId
    },
    items() {
      return this.accounts.filter((acc)=>{
        return acc.groups[this.tabActiveGroupId].isAvailable
      })
    },
    marketOpenBets() {
      return marketOpenBets
    }
  },
  methods: {
    loadAccounts() {
      AccountService.getAccountSettingPayrates()
      .then((response)=>{
        if(response.success) {
          this.accounts = response.data

          if(!this.tabActiveGroupId) {
            this.tabActiveGroupId = this.groups[0]?._id
          }

          if(!this.tabActiveRateId) {
            this.tabActiveRateId = this.payrates[0]?._id
          }
        }
      })
    },
    toggleChange(account) {
      const updateData = {
        accountId: account._id,
        groupId: this.tabActiveGroupId,
        rateId: this.tabActiveRateId,
        rateTitle: account.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId].rateTitle,
        isAvailable: account.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId].isAvailable,
        rateData: account.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId].rates
      }
      console.log('updateData', updateData)
      AccountService.updateAccountSettingPayrate(updateData)
      .then((response) => {
        if(response.success) {
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e.message)
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.loadAccounts()
      })
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadAccounts()
      }
    },
    editPayrate(account) {
      this.editData = {
        account,
        payRate: {
          accountId: account._id,
          groupId: this.tabActiveGroupId,
          rateId: this.tabActiveRateId,
          rateTitle: account.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId].rateTitle,
          isAvailable: account.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId].isAvailable,
          rateData: account.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId].rates
        }
      }
      this.isShowModal = true
    }
  },
  created() {
    this.tabActiveGroupId = this.groups[0]?._id

    if(this.groups.length === 0) {
      this.$store.dispatch('reloadMarketGroups')
    }

    if(this.payrates.length === 0) {
      this.$store.dispatch('reloadMarketPayrates')
    }
  },
  mounted() {
    this.loadAccounts()
  }
}
</script>
<style lang="scss" scoped>
.user-setting-payrate {
  table {
    th {
      border-top: 0;
      //font-weight: normal;
      font-size: 90%;
      vertical-align: middle;
    }
    td {
      vertical-align: middle;
    }
  }
}
</style>
